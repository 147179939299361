@import url(https://fonts.googleapis.com/earlyaccess/notosansjapanese.css);

@font-face {
  font-family: "ProximaSoft-Regular";
  font-display: swap;
  src: url("../webfonts/ProximaSoft-Regular.eot");
  src: url("../webfonts/ProximaSoft-Regular.eot?#iefix") format("embedded-opentype"),
  url("../webfonts/ProximaSoft-Regular.woff2") format("woff2"),
  url("../webfonts/ProximaSoft-Regular.woff") format("woff"),
  url("../webfonts/ProximaSoft-Regular.ttf") format("truetype");
}
